<template>
	<div class="">
		<div class="gg-container">
			<div class="" style="display: flex;">
				<div class="search-container-fn-input">
					<label>医师姓名：</label>
					<el-input style="width: 200px" size="small" placeholder="请输入医师姓名" prefix-icon="el-icon-search" clearable
										@change="reset" v-model="searchParams.doctor_name">
					</el-input>
				</div>
				<div class="search-container-fn-input">
					<label>手机号：</label>
					<el-input style="width: 200px" size="small" placeholder="请输入手机号" prefix-icon="el-icon-search" clearable
										@change="reset" v-model="searchParams.mobile">
					</el-input>
				</div>
				<div class="search-container-fn-input">
					<label>科室：</label>
					<el-cascader v-model="searchParams.depart_id" style="width: 200px" clearable size="small"
											 :options="departSymptom" @change="reset" :props="{ checkStrictly: true }">
					</el-cascader>
				</div>
				<div class="search-container-fn-input">
					<label>职称：</label>
					<el-select style="width: 200px" @change="reset" v-model="searchParams.professional" clearable
										 placeholder="请选择" size="small">
						<el-option v-for="item in doctorDic.doctor_title" :key="item.value" :label="item.label"
											 :value="item.value">
						</el-option>
					</el-select>
				</div>
			
			</div>
			
			
			
			<el-table :data="tableData" v-loading="listLoading" border style="width: 100%;margin-top: 20px;">
				<el-table-column prop="doctors_id" label="ID" align="center" width="80">
				</el-table-column>
				<el-table-column prop="doctor_name" align="center" label="医师姓名">
					<template slot-scope="{row}">
						<el-button type="text" size="small" @click="currUser = row;getInfo(row.doctors_id)">
							{{ row.doctor_name }}
						</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="doc_code" label="编号" align="center" width="100">
				</el-table-column>
				<!-- <el-table-column prop="status_name" width="90" align="center" label="审核状态">
				</el-table-column> -->
				<el-table-column prop="upload_name" width="80" align="center" label="备案状态">
				</el-table-column>
				<el-table-column prop="mobile" label="注册手机号" align="center" width="110">
				</el-table-column>
				<el-table-column prop="head_pic" width="80" align="center" label="医师头像">
					<template slot-scope="{row}">
						<el-avatar :src="row.head_pic"></el-avatar>
					</template>
				</el-table-column>
				<el-table-column prop="ge_name" width="80" align="center" label="性别">
				</el-table-column>
				<el-table-column prop="created_at" align="center" width="160" label="注册时间">
				</el-table-column>
				<!-- <el-table-column prop="" align="center" width="160" label="就诊设置">
					<template slot-scope="scope">
						<el-button type="text" @click="handleSetInquiryPrice(scope.$index, scope.row)">问诊价格</el-button>
					</template>
				</el-table-column> -->
				<el-table-column prop="organization_name" align="center" width="310" label="执业机构">
				</el-table-column>
				<el-table-column prop="professionalName" align="center" label="职称">
				</el-table-column>
				<el-table-column prop="depart_name" align="center" label="科室">
				</el-table-column>
				<el-table-column prop="address" label="操作" align="center" fixed="right" width="145">
					<template slot-scope="{ row }">
						<el-button type="primary" @click="signDoc(row)" size="mini"
											 icon="el-icon-edit">签约
						</el-button>
						<!-- <el-button style="padding: 7px 8px;margin-left: 5px;" type="danger"
											 @click="physicianTermination(1,row.doctors_id)" size="mini" icon="el-icon-warning">解约
						</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<el-drawer :append-to-body="true" size="76%" :visible.sync="dialogTableVisible" direction="rtl">
				<physicianDetail @closeDrawer="closeDrawer" doctorType="1" :type="'view'" :info="info"
												 :id="currUser.doctors_id" v-if="dialogTableVisible">
				</physicianDetail>
			</el-drawer>
			<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
									@pagination="getList"/>
		</div>
		
		
		<!-- 问诊价格设置弹出框 -->
		<el-dialog
			v-loading="listLoading"
			:visible.sync="isActiveDialog"
			width="900px"
			inline
			:close-on-click-modal="false"
			:before-close="handleInquiryPriceCancel">
			<div slot="title" style="font-size: 14px;color: #606266;font-weight: 700; margin-bottom: 10px">
				<div class="required"></div>
				<span>问诊价格</span></div>
			
			
			<div class="inquiry">
				<div class="inquiryImg m-t-10" style="margin-bottom: 10px">
					<img src="../../assets/graphic.png">
					<span style="margin-right: 3px">图文复诊</span>
					<el-switch
						v-model="picPrice.pic_status"
						active-color="#13ce66"
						inactive-color="#ff4949"
						:active-value="1"
						:inactive-value="0">
					</el-switch>
				</div>
				<div class="inquiryText m-t-10">
					<span style="margin-right: 3px">首次复诊 ¥</span>
					<el-input
						placeholder="价格"
						size="mini"
						maxlength="6"
						v-model.trim="picPrice.FirstConsultation"
						clearable>
					</el-input>
					<span style="margin-left: 5px;margin-right: 3px">常规复诊 ¥</span>
					<el-input
						placeholder="价格"
						size="mini"
						maxlength="6"
						v-model.trim="picPrice.commonConsultation"
						clearable>
					</el-input>
					<span style="margin-left: 5px;margin-right: 3px">问诊时长</span>
					<el-input
						placeholder=""
						size="mini"
						maxlength="6"
						v-model.number="picPrice.pic_time"
						clearable>
					</el-input>
					<el-select class="select-container" size="mini" v-model="picPrice.pic_time_type" placeholder="请选择">
						<el-option
							v-for="item in inquiryTimeOptions"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="inquiry" style="margin-top: 20px; margin-bottom: 20px">
				<div class="inquiryImg m-t-10">
					<img src="../../assets/video.png">
					<span style="margin-right: 3px">视频复诊</span>
					<el-switch
						v-model="videoPrice.video_status"
						active-color="#13ce66"
						inactive-color="#ff4949"
						:active-value="1"
						:inactive-value="0">
					</el-switch>
				</div>
				<div class="inquiryText m-t-10" style="margin-top: 10px">
					<span style="margin-right: 3px">首次复诊 ¥</span>
					<el-input
						placeholder="价格"
						size="mini"
						maxlength="6"
						v-model.trim="videoPrice.FirstConsultation"
						clearable>
					</el-input>
					<span style="margin-left: 5px;margin-right: 3px">常规复诊 ¥</span>
					<el-input
						placeholder="价格"
						size="mini"
						maxlength="4"
						v-model.trim="videoPrice.commonConsultation"
						clearable>
					</el-input>
					<span style="margin-left: 5px;margin-right: 3px">问诊时长</span>
					<el-input
						placeholder=""
						size="mini"
						maxlength="4"
						v-model.number="videoPrice.video_time"
						clearable>
					</el-input>
					<el-select size="mini" class="select-container" v-model="videoPrice.video_time_type" placeholder="请选择">
						<el-option
							v-for="item in inquiryTimeOptions"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			
			
			<div slot="footer" class="dialog-footer">
				<el-button class="mgr-20" size="mini" @click="handleInquiryPriceCancel">取 消</el-button>
				<el-button size="mini" type="primary" @click="submitInquiryPriceForm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>


    <!--设置费率-->
				<el-dialog title="签约" :visible.sync="reviewed" v-if="reviewed"
					width="40%">
					<el-form ref="ruleForm1" :model="form1" label-width="110px" :rules="rules1" class="demo-ruleForm">
						<el-form-item label="医生姓名">
							{{form1.doctor_name}}
						</el-form-item>
						<el-form-item label="其他补贴费率" prop="pres_rate">
							<el-input v-model="form1.pres_rate" style="width: 70%;"></el-input> %
						</el-form-item>
						<!-- <div slot="footer" class="dialog-footer"> -->
              <el-form-item>
                <el-button type="success" size="small" @click="saveRateForm()">确定</el-button>
              </el-form-item>
							
						<!-- </div> -->
			
					</el-form>
				</el-dialog>
	
	</div>
</template>

<script>
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import physicianDetail from "../doctorManage/physicianDetail2";
import {
  getDoctorsDetailNew,
  physicianTermination,
  getDoctorsListNew,
  postUpdateDiagnosisSetting,
  getDiagnosisSetting,
  signDoctor
} from "@/api/audit/doctor2.0.js";
import {
  Message
} from "element-ui";
import {
  mapState
} from "vuex";

export default {
  name: "doctorConfirm",
  created () {
    this.getList()
  },
  data () {
    return {
      form1:{

      },
      reviewed:false,
      info: {},
      showPop: false,
      type: 1, //1--医师,2--药师
      selected: false,
      clearFilter: false,
      dialogTitle: '',
      dialogType: '',
      datetimerange: [],
      currUser: {},
      dialogTableVisible: false,
      searchParams: {
        orderTime: []
      },
      total: 0,
      listLoading: false,
      fullscreenLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },
      tableData: [],
      options: [],
      ruleForm: {
        examine: ''
      },
      isActiveDialog: false,
      picPrice: {
        FirstConsultation: 0.01,
        commonConsultation: 0.01,
        pic_time_type: 0, // 视频问诊时间格式类型 0分 1时 2天
        pic_time: 1,
        pic_status: 1
      },
      videoPrice: {
        FirstConsultation: 0.01,
        commonConsultation: 0.01,
        video_time_type: 0, // 视频问诊时间格式类型 0分 1时 2天
        video_time: 1,
        video_status: 1,
      },
      inquiryTimeOptions: [{
        value: 0,
        label: '分钟'
      }, {
        value: 1,
        label: '时'
      }, {
        value: 2,
        label: '天'
      },],
      inquiryTimeOptionsValue: '',
      rules: {
        examine: [{
          required: true,
          message: '请选择审核结果',
          trigger: 'change'
        },],
      },
      rules1:{
					pres_rate:[{
						required: true,
						message: "请填写其他补贴费率",
						trigger: "blur"
					}]
				},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      curDoctor: {},
    };
  },
  watch: {
    dialogTableVisible (n, v) {
      if (!n) {
        this.getList()
      }
    }
  },
  computed: {
    ...mapState({
      filingStatus: state => state.dictionary.filingStatus,
      doctorStatus: state => state.dictionary.doctorStatus,
      doctorDic: state => state.dictionary.doctorDic, //字典
      departSymptom: state => state.dictionary.departDic, //字典
      is_self: state => state.user.is_self,
      proxy_level:state => state.level

    }),

  },
  components: {
    Pagination,
    physicianDetail
  },
  mounted () {
    this.$store.dispatch('dictionary/getDicData', [{
      stateName: 'doctorDic',
      api: '/admin/hospital/getDicts'
    }]);
    this.$store.dispatch('dictionary/getDicData', [{
      stateName: 'departDic',
      api: '/admin/hospital/getDeparts'
    }]);
  },
  methods: {
    //关闭弹窗,刷新列表
    closeDrawer (e) {

    },
    saveRateForm(){
      let params = {
        pres_rate:this.form1.pres_rate
      }
      signDoctor(this.form1.doctors_id,params).then(res=>{
        this.reviewed = false
        if(200 == res.code){
          this.getList()
          return this.$message({
            type: 'success',
            message: '签约成功!'
          });
          
        }else{
          return this.$message.error(res.msg)
        }
      })
    },
    //暂未开放
    signDoc (row) {
      this.form1 = row
      this.reviewed = true
        // this.$confirm('确定签约该医师?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   signDoctor(row.doctors_id).then(res=>{
        //     if(200 == res.code){
        //       this.getList()
        //       return this.$message({
        //         type: 'success',
        //         message: '签约成功!'
        //       });
        //     }
        //   }).catch(err => {
        //     console.log(err)
        //   })
         
        // }).catch(() => {
        //   this.$message({
        //     type: 'info',
        //     message: '已取消'
        //   });          
        // });
    },
    //清除高级搜索
    clearState () {
      this.searchParams.status = ''
      this.searchParams.upload_status = ''
    },
    approvalStatus (e) {
      this.searchParams.status = e
    },
    clearText () {
      this.searchParams.doctor_name = ''
      this.searchParams.mobile = ''
      this.searchParams.depart_id = ''
      this.searchParams.professional = ''
      this.getList()
    },
    popoShow () {
      this.clearFilter = true
      this.selected = true
    },
    popoHide () {
      this.selected = false
      this.clearFilter = false
    },
    //解约医师
    physicianTermination (type, id) {

      this.$confirm(type == 1 ? '是否解约该医生?' : '是否删除该医生', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        let params = {
          id: id
        }
        physicianTermination(params).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: type == 1 ? '解约成功!' : '删除成功'
            });
            this.getList()
          }

        }).catch(err => {
          this.$message({
            type: 'error',
            message: err.message
          });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: type == 1 ? '已取消解约' : '已取消删除'
        });
      });
    },
    getInfo (id) {

      // this.fullscreenLoading = true;
      let params = {
        id: id
      }
      getDoctorsDetailNew(params).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.info = {
            ..._data
          }
          this.dialogTableVisible = true
        }
        if (this.info.depart_id) {
          this.info.depart_id = Number(this.info.depart_id)
        }
        this.fullscreenLoading = false
      }).catch(error => {
        Message({
          message: error.message,
          type: 'error'
        })
        this.fullscreenLoading = false
      })
    },
    getList () {
      this.listLoading = true
      this.fullscreenLoading = true;
      let params = {
        type: this.type,
        status:5,
        ...this.searchParams
      }
      if (params.depart_id && params.depart_id.length == 2) {
        params.depart_id.splice(0, 1)
      }
      params.page = this.listQuery.page
      params.page_size = this.listQuery.limit
      getDoctorsListNew(params).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.total = _data.total
          this.listQuery.limit = Number(_data.per_page)
          this.tableData = _data.list
        }
        this.fullscreenLoading = false
        this.listLoading = false
      }).catch(error => {
        Message({
          message: error.message,
          type: 'error'
        })
        this.listLoading = false
        this.fullscreenLoading = false
      })
    },
    reset () {
			// this.searchParams = {};
      this.listQuery.page = 1;
				this.getList()
    },

    dateChange (val) {
      if (val && val.length) {
        this.searchParams.created_at = JSON.stringify(val)
      } else {
        this.searchParams.created_at = []
      }
      this.getList()
    },
    editDoctor () {
      if(this.level == 0){
        this.$router.push({
          path: '/credentManage/addDoctor',
          query: {
            type: '1',
            name: 'doctor'
          }
        })
      }else{
        this.$router.push({
          path: '/proxyDoctor/proxyAddDoc',
          query: {
            type: '1',
            name: 'doctor'
          }
        })
      }
      
    },
    async handleSetInquiryPrice (index, row) {
      console.log('设置问诊价格')
      this.curDoctor = row
      try {
        var res = await getDiagnosisSetting(row.doctors_id);
        console.log(res)
				if(res.code == 200 ) {
				  var _data = res.data
          this.picPrice = {
            FirstConsultation: _data.first_pic_price * 1,
            commonConsultation: _data.pic_price * 1,
            pic_time_type: _data.pic_time_type, // 视频问诊时间格式类型 0分 1时 2天
            pic_time: _data.pic_time,
            pic_status: _data.pic_status
          }
          this.videoPrice = {
            FirstConsultation: _data.first_video_price * 1,
            commonConsultation: _data.video_price * 1,
            video_time_type: _data.video_time_type, // 视频问诊时间格式类型 0分 1时 2天
            video_time: _data.video_time,
            video_status: _data.video_status
          }
				} else {
				  this.$message({
            type: 'error',
            message: res.msg,
					})
          this.picPrice = {
            FirstConsultation: 0.01,
            commonConsultation: 0.01,
            pic_time_type: 0, // 视频问诊时间格式类型 0分 1时 2天
            pic_time: 1,
            pic_status: 1
          }
          this.videoPrice = {
            FirstConsultation: 0.01,
            commonConsultation: 0.01,
            video_time_type: 0, // 视频问诊时间格式类型 0分 1时 2天
            video_time: 1,
            video_status: 1,
          }
				}
      
      } catch (e) {
        console.log(e)
      }
      this.isActiveDialog = true
    },

    handleInquiryPriceCancel () {
      this.isActiveDialog = false
    },
    submitInquiryPriceForm () {
      var reg = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
      if (!reg.test(this.picPrice.commonConsultation) || !reg.test(this.videoPrice.commonConsultation)) {
        this.$message({
          type: 'error',
          message: '输入错误, 常规复诊错误',
        })
      } else if (!reg.test(this.picPrice.FirstConsultation) || !reg.test(this.videoPrice.FirstConsultation)) {
        this.$message({
          type: 'error',
          message: '输入错误, 首次复诊错误',
        })
      } else if (this.picPrice.pic_time < 1 || this.videoPrice.video_time < 1) {
        this.$message({
          type: 'error',
          message: '输入错误, 接单次数需要大于0',
        })
      } else {
        console.log('postUpdateDiagnosisSetting')
        this.isActiveDialog = false
        var data = {
          video_time_type: this.videoPrice.video_time_type,
          pic_time_type: this.picPrice.pic_time_type,
          video_status: this.videoPrice.video_status,
          pic_status: this.picPrice.pic_status,
          first_video_price: this.videoPrice.FirstConsultation,
          first_pic_price: this.picPrice.FirstConsultation,
          pic_price: this.picPrice.commonConsultation,
          video_price: this.videoPrice.commonConsultation,
          pic_time: this.picPrice.pic_time,
          video_time: this.videoPrice.video_time,
        }
        console.log(data)
        postUpdateDiagnosisSetting(data, this.curDoctor.doctors_id).then(res => {
          if(res.code == 200) {
            this.$message({
              type: 'success',
              message: '修改成功',
            })
					}else{
            this.$message({
							type: 'error',
							message: res.msg,
						})
					}
					
        })
      }
      // this.isActiveDialog = false
    },

    editSuccess () {
      alert('编辑成功')
      this.dialogTableVisible = false
      this.getList()
    }
  },
  filters: {
    controllshow (str, num) {
      //如果当前字符串小于nunm，返回原值
      if (str) {
        if (str.length <= num) {
          return str;
        }
        if (str.length > num) {
          return str.substr(0, num - 1) + "..."
        }
      } else {
        return str
      }

    }

  }
}
</script>

<style scoped lang="scss">
	.horizontal-collapse-transition {
		transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
	}
	
	.doc-title {
		position: relative;
		height: 30px;
		
		img {
			width: 25px;
			height: 25px;
			position: absolute;
			top: 2px;
		}
		
		span {
			display: inline-block;
			margin-left: 30px;
			line-height: 30px;
			font-weight: bold;
		}
	}
	
	::v-deep .el-drawer {
		bottom: 0 !important;
	}
	
	::v-deep .el-drawer__header {
		padding: 0;
		margin: 0;
	}
	
	::v-deep .el-tabs__header {
		margin: 0;
	}
	
	::v-deep .el-drawer.rtl {
		overflow: auto;
		height: 91vh;
		bottom: 0;
		margin-top: 9vh;
		position: absolute;
	}
	
	::v-deep .el-drawer__container ::-webkit-scrollbar {
		display: none;
	}
	
	
	/*dialog对话框样式调整*/
	::v-deep .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	::v-deep .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	/*dialog对话框样式调整*/
	::v-deep .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	::v-deep .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	
	/*弹出框表单配置*/
	::v-deep .el-form-item__label {
		padding-bottom: 0;
	}
	
	::v-deep .el-form-item__content {
		width: 280px;
	}
	
	.from-item-second {
		margin-left: 0;
	}
	
	::v-deep .from-item-third {
		.el-form-item__content {
			width: 540px;
		}
		
		.el-select {
			width: 540px !important;
		}
	}
	
	::v-deep .el-cascader {
		width: 280px;
	}
	
	::v-deep .el-select {
		width: 280px !important;
	}
	
	::v-deep .el-date-editor {
		width: 280px !important;
	}
	
	/*select 宽度配置*/
	::v-deep .el-select {
		width: 85px;
	}
	
	.inquiry {
		font-size: 14px;
		font-weight: bold;
		
		.inquiryImg {
			display: flex;
			align-items: center;
			
			img {
				width: 20px;
				height: 20px;
			}
			
			span {
				display: inline-block;
				margin-left: 8px;
			}
		}
	}
	
	
	/*竖杠+后面文本的样式*/
	.required {
		margin-top: 2px;
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		top: 20px;
	}
	
	.required + span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}
	
	.inquiryText {
		::v-deep .el-input {
			width: 100px;
		}
	}
	
	.inquiry {
		::v-deep .el-input {
			width: 100px;
		}
	}
	
	.select-container {
		::v-deep .el-input {
			width: 80px;
		}
	}
</style>
